

/* ====================== [ Start Footer ] ====================== */

footer{
    color: #fff;
    padding: 100px 0;
    background: #18191d;

    &.sub-bg{
        background: #18191d;
    }

    .item{

        .title{

            h5{
                margin-bottom: 30px;
            }
        }

        li{
            display: flex;
            margin-bottom: 25px;

            &:last-of-type{
                margin-bottom: 0;
            }

            h6{
                font-size: 14px;
                font-weight: 600;
            }

            .icon{
                font-size: 30px;
                margin-right: 30px;
            }

            .img{
                width: 150px;
                margin-right: 15px;
            }

            .sm-post{

                p{
                    font-size: 13px;
                }

                .date{
                    font-size: 12px;
                    color: $main-color;
                }
            }

            .subscribe{
                width: 100%;
                position: relative;

                input{
                    width: 100%;
                    padding: 15px;
                    border-radius: 30px;
                    border: 1px solid rgba(255,255,255,.05);
                    background: transparent;
                    color: #fff;
                    font-size: 13px;
                }

                .subs{
                    position: absolute;
                    top: 0;
                    right: 0;
                    width: 50px;
                    height: 50px;
                    line-height: 50px;
                    text-align: center;
                    border-radius: 50%;
                    background: $main-color;
                    color: #000;
                }
            }
        }

        .logo{
            margin-bottom: 50px;
            
            img{
                width: 50px;
            }
        }

        .social{

            a{
                width: 60px;
                height: 60px;
                line-height: 60px;
                text-align: center;
                border-radius: 50%;
                border: 1px solid rgba(255,255,255,.05);
                font-size: 13px;
                margin-right: 10px;
            }
        }

        .copy-right{
            margin-top: 30px;

            p{
                font-size: 13px;
                font-weight: 300;
                text-transform: uppercase;
                letter-spacing: 3px;

                a{
                    color: $main-color;
                }
            }
        }
    }
}

/* ====================== [ End Footer ] ====================== */