

/* ====================== [ Start services ] ====================== */

.services{
	position: relative;

	.container{
		position: relative;
		z-index: 2;
	}

	.item-box{
		padding: 80px 30px;
		text-align: center;
		border: 1px solid rgba(0,0,0,.08);

		.icon{
			position: relative;
			width: 80px;
			height: 80px;
			line-height: 80px;
			font-size: 40px;
			margin-bottom: 30px;

			&:after{
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				border-radius: 50%;
				background: linear-gradient(to right, rgb(18, 194, 233), rgb(196, 113, 237), rgb(246, 79, 89));
				z-index: -1;
				opacity: .1;
			}
		}

		h6{
			line-height: 1.8;
			text-transform: capitalize;
			margin-bottom: 10px;
		}
	}

	.rone{

		> div{
			position: relative;

			&:nth-of-type(2){
				margin-bottom: -50px;
				margin-top: auto;
			}
		}
	}

	.rtwo{

		> div{
			position: relative;

			&:nth-of-type(2){
				margin-bottom: -50px;
				margin-top: auto;
			}
		}
	}
	
	.mas-item{
		padding: 40px;
		margin: 50px 0;
		background: #18191d;
		position: relative;
		z-index: 2;

		&:after{
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: #18191d;
			transition: all .4s;
			z-index: -1;
		}

		.bg-color{
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: $main-color;
			transition: all .4s;
			z-index: -2;
		}

		&:hover{

			.bg-color{
				top: 10px;
				left: 10px;
			}
		}

		.icon{
			font-size: 45px;
			color: $main-color;
			margin-bottom: 30px;
		}

		h6{
			font-weight: 600;
			font-size: 16px;
			line-height: 1.7;
		}

		.more{
			margin-top: 20px;
			width: 100%;
			width: 45px;
			height: 45px;
			line-height: 45px;
			text-align: center;
			font-size: 12px;
			border-radius: 50%;
			border: 2px solid rgba(255,255,255,.05);
		}
	}

	.step-item{
		text-align: center;
		padding: 60px;
		margin: 60px auto;
		background: #f5f5f5;
		position: relative;

		&.xbottom{
			bottom: -60px;
		}

		&.xtop{
			top: -60px;
		}

		&.xcolor{
			background: #fff;
			border: 1px solid #eee;
		}

		.icon{
			color: $main-color;
			background: linear-gradient(to right, #12c2e9, #c471ed, #f64f59);
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;
			font-size: 40px;
			margin-bottom: 30px;
		}

		h6{
			margin-bottom: 20px;
		}
	}

	.item{
		text-align: center;
		padding: 60px;
		background: #151921;
		position: relative;

		.icon{
			color: $main-color;
			font-size: 40px;
			margin-bottom: 40px;
		}

		h6{
			margin-bottom: 15px;
		}
	}

	&.lficon{

		.item-box{
			text-align: left;
			display: flex;
			margin-top: 30px;
			padding: 60px 40px;
			border-radius: 20px;

			.icon{
				text-align: center;
			}

			.cont{
				padding-left: 30px;
			}
		}
	}
}

.feat{

	.items{
		padding: 60px 40px;
		position: relative;
		z-index: 3;

		&:after{
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			right: 1px;
			background: #fff;
			z-index: -1;
		}

		&:before{
			content: '';
			position: absolute;
			top: -1px;
			left: -1px;
			bottom: -1px;
			right: 0px;
			background: linear-gradient(to right, rgb(18, 194, 233), rgb(196, 113, 237), rgb(246, 79, 89));
			z-index: -2;
			opacity: 0;
			transition: all .4s;
		}

		.icon{
			color: #aaa;
			font-size: 70px;
			margin-bottom: 30px;

			svg{
				width: 1em;
				height: 1em;
			}
		}

		h5{
			font-size: 22px;
			line-height: 1.5;
			margin-bottom: 20px;
		}

		.more-stroke{
			margin-top: 30px;

			span{
				width: 0;
				height: 2px;
				background: #111;
				position: relative;

				&:after, &:before{
					content: '';
					width: 0;
					height: 2px;
					background: #111;
					position: absolute;
					right: -1px;
				}

				&:after{
					transform: rotate(40deg);
					top: -4px;
				}

				&:before{
					transform: rotate(-40deg);
					top: 4px;
				}
			}
		}

		&.active{
			
			&:before{
				opacity: 1;
			}

			.more-stroke{

				span{
					width: 30px;
					transition: width .3s;

					&:after, &:before{
						width: 12px;
						transition: width .3s;
						transition-delay: .3s;
					}
				}
			}
		}

		&:hover{

			.more-stroke{

				span{
					width: 30px;

					&:after, &:before{
						width: 12px;
					}
				}
			}
		}
	}
}

.serv-arch{

	.item{
		padding: 50px 30px;
		position: relative;
		z-index: 2;

		&:after{
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background: #fff;
			border: 1px solid rgba(0,0,0,.07);
			transition: all .4s;
			z-index: -1;
		}

		&.active{

			&:after{
				opacity: .85;
			}
		}


		&:hover{

			&:after{
				opacity: .75;
				background: #232323;
			}

			h5{
				color: #fff;
			}

			p{
				color: #eee;
				transform: translateY(0);
				opacity: 1;
			}
		}

		.numb{
			font-size: 60px;
			font-weight: 700;
			margin-bottom: 10px;
			color: transparent;
			-webkit-text-stroke: 1px #b19777;
		}

		h5{
			text-transform: uppercase;
			letter-spacing: 1px;
		}

		p{
			margin-top: 60px;
			opacity: 0;
			transform: translateY(30px);
			transition: all .4s;
		}

		.more{
			margin-top: 50px;
		}
	}
}

.min-area {

	.img{
		padding: 120px 0 120px 80px;
		position: relative;
		z-index: 3;

		&:after{
			content: '';
			position: absolute;
			top: 0;
			right: 120px;
			bottom: 0;
			left: -300px;
			background: #f5f5f5;
			z-index: -1;
		}
	}

	.content{
		padding-left: 40px;

		h4{
			font-weight: 700;
			font-size: 40px;
			line-height: 1.5;
			margin-bottom: 20px;
		}

		ul{
			margin: 0;

			li{
				margin-top: 25px;
				padding-left: 25px;
				position: relative;

				&:after{
					content: '';
					width: 10px;
					height: 10px;
					border: 1px solid #999;
					border-radius: 50%;
					position: absolute;
					left: 0;
					top: 2px;
				}
			}

			&.feat{

				li{
					padding: 0 0 30px 0;
					border-bottom: 1px solid rgba(255,255,255,.05);

					&:last-of-type{
						padding: 0;
						border: 0;
					}

					&:after{
						display: none;
					}

					h6{
						font-weight: 500;
						margin-bottom: 5px;
						
						span{
							color: $main-color;
							width: 30px;
							height: 30px;
							line-height: 30px;
							text-align: center;
							font-size: 13px;
							border: 1px solid $main-color;
							border-radius: 50%;
							margin-right: 10px;
						}
					}

					p{
						font-size: 13px;
						padding-left: 45px;
					}
				}
			}
		}
	}
}

/* ====================== [ End services ] ====================== */