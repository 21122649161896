

/* ====================== [ Start Work ] ====================== */

.portfolio{
	position: relative;

	.filtering{
		padding-left: 50px;

		.filter{
			padding: 15px;
			background: rgba(0, 0, 0, 0.04);
			border-radius: 30px;
			display: inline-block;
		}

		span{
			font-weight: 500;
			font-size: 12px;
			letter-spacing: 1px;
			text-transform: uppercase;
			margin: 0 20px;
			cursor: pointer;
			position: relative;

			&.active{
				color: $main-color;
			}

			&:after{
				content: '';
				width: 7px;
				height: 7px;
				background: $main-color;
				border-radius: 50%;
				position: absolute;
				right: -24px;
				top: 40%;
			}

			&:last-of-type{

				&:after{
					display: none;
				}
			}
		}

		&.smplx{

			.filter{
				padding: 0;
				background: transparent;
				border-radius: 0;
				display: inline-block;
			}
	
			span{
				font-weight: 500;
				font-size: 12px;
				letter-spacing: 1px;
				text-transform: uppercase;
				margin: 0 20px;
				cursor: pointer;
				position: relative;
	
				&.active{
					color: $main-color;
				}
	
				&:after{
					content: '';
					width: 7px;
					height: 7px;
					background: $main-color;
					border-radius: 50%;
					position: absolute;
					right: -24px;
					top: 40%;
				}
	
				&:last-of-type{
	
					&:after{
						display: none;
					}
				}
			}
		}
	}

	.gallery{

		.items{
			padding: 0 50px;
			margin: 50px 0;

			&.lg-mr{
				margin-top: 100px;
			}

			.item-img{
				overflow: hidden;
				position: relative;
				clip-path: inset(0);
				transition: all .3s;

				> a{
					display: block;
				}

				&:hover{
					clip-path: inset(5px 10px);

					img{
						transform: scale(1.05);
					}

					.cont{
						opacity: 1;
					}
				}

				img{
					height: 100%;
					width: 100%;
					object-fit: cover;
					transition: all .5s;
				}

				.cont{
					position: absolute;
					bottom: 15px;
					left: 0;
					text-align: left;
					padding-left: 30px;
					opacity: 0;
					transition: all .4s;

					span{
						color: #eee;
						text-transform: uppercase;
						letter-spacing: 2px;
						font-size: 13px;
	
						a{
							margin: 0 10px 0 0;
						}
					}
				}
			}

			.cont{
				margin-top: 30px;
				text-align: center;

				h6{
					font-weight: 600;
					margin-bottom: 5px;
				}

				span{
					color: #999;
					text-transform: uppercase;
					letter-spacing: 2px;
					font-weight: 400;
					font-size: 13px;

					a{
						margin: 0 5px;
					}
				}
			}
		}

		.bords{

			.item-img{
				padding: 10px;
				border: 1px solid rgba(255,255,255,.03);
			}

			.cont{
				border: 1px solid rgba(255,255,255,.03);
				margin-top: 0;
				border-top: 0;
				padding: 10px;
			}
		}
	}

	&.three-column{

		.gallery{

			.items{
				padding: 0 15px;
				margin: 25px 0;
			}
		}
	}
}

.po-arch{

	.filtering{
		padding-left: 30px;

		.filter{
			padding: 0;
			background: transparent;
			border-radius: 0;
			display: inline-block;
		}

		span{
			font-weight: 400;
			font-size: 15px;
			letter-spacing: 2px;
			text-transform: uppercase;
			margin: 0 20px;
			cursor: pointer;
			position: relative;

			&.active{
				color: #b19777;
			}

			&:after{
				display: none;
			}
		}
	}

	.gallery{

		.items{
			position: relative;
	
			.cont{
				text-align: left;
				padding: 30px;
				padding-left: 0;
				background: #fff;
				display: inline-block;
				margin: 0;
				position: relative;
				margin-top: -30px;

				.tags{
					
					a{
						font-size: 17px;
						margin: 0 10px 0 0;
					}
				}

				h5{
					font-weight: 500;
					text-transform: uppercase;
					letter-spacing: 1px;
					margin: 0 0 5px 0;
				}
			}
		}
	}
}

.portfolio-cr{

	.filtering{

		.filter{
			display: inline-block;
		}

		span{
			font-size: 13px;
			font-weight: 500;
			letter-spacing: 1px;
			text-transform: uppercase;
			margin: 8px 20px;
			padding-bottom: 10px;
			cursor: pointer;
			position: relative;

			&:after{
				content: '';
				width: 100%;
				height: 2px;
				background-color: $main-color;
				position: absolute;
				left: 0;
				bottom: -10px;
				opacity: 0;
				transition: all .4s;
			}
		}

		.active{
			color: $main-color;

			&:after{
				bottom: 0;
				opacity: 1;
			}
		}
	}

	.items{
		padding: 0 15px;
		margin-top: 30px;
		width: 50%;
		position: relative;
		overflow: hidden;

		&:hover{

			.cont{
				opacity: 1;
				transform: translateY(0);
			}
		}

		&.width2{
			width: 25%;
		}

		.cont{
			position: absolute;
			bottom: 15px;
			left: 30px;
			right: 30px;
			padding: 20px;
			backdrop-filter: blur(7px);
			background: rgba(255,255,255,.48);
			border: 1px solid rgba(255, 255, 255, 0.3);
			border-radius: 5px;
			opacity: 0;
			transform: translateY(50%);
			transition: all .4s;
			
			&.flex{
				display: flex;
			}

			h6{
				font-size: 17px;
				font-weight: 600;
				line-height: 1.2;
				margin-bottom: 5px;
			}

			span{
				margin-left: auto;
				text-transform: uppercase;
				letter-spacing: 1px;
				font-weight: 400;
				font-size: 12px;

				a{
					opacity: .7;
					margin: 0 5px;
					line-height: 1.2;
				}
			}
		}
	}
}

.portfolio-frl{

	.filtering{

		.filter{
			display: inline-block;
		}

		span{
			font-size: 13px;
			letter-spacing: 1px;
			text-transform: uppercase;
			margin: 8px 20px;
			padding-bottom: 10px;
			cursor: pointer;
			position: relative;

			&:after{
				content: '';
				width: 100%;
				height: 2px;
				background-color: $main-color;
				position: absolute;
				left: 0;
				bottom: -10px;
				opacity: 0;
				transition: all .4s;
			}
		}

		.active{
			color: $main-color;

			&:after{
				bottom: 0;
				opacity: 1;
			}
		}
	}

	.gallery{

		.items{
			padding: 0 40px;
			margin: 40px 0;

			&.lg-mr{
				margin-top: 100px;
			}

			.item-img{
				overflow: hidden;
				position: relative;
				transition: all .3s;
				background: rgba(0, 0, 0, 0.04);
				padding: 30px;
				border-radius: 5px;

				> a{
					display: block;
				}

				&:hover{

					img{
						clip-path: inset(5px 10px);
					}

					.cont{
						opacity: 1;
					}
				}

				img{
					height: 100%;
					width: 100%;
					clip-path: inset(0);
					object-fit: cover;
					transition: all .5s;
				}
			}

			.cont{
				margin-bottom: 30px;

				h6{
					font-size: 22px;
					font-weight: 500;
					margin-bottom: 5px;
				}

				p{
					font-size: 13px;
				}
			}

			.tags{
				margin-top: 30px;

				span{
					padding: 8px 12px;
					background: rgba(0, 0, 0, 0.04);
					margin-right: 5px;
					font-size: 12px;
				}
			}
		}
	}
}

.portfolio.full-bg {
	position: relative;

	.cluom{
		height: 90vh;
		padding: 0;
		position: relative;
		z-index: 2;
		border-right: 1px solid rgba(255,255,255,.3);
		transition: all .4s;

		&.current{
			background: rgba(0,0,0,.3);
		}

		&:hover{

			.info{
				color: inherit;

				&:after{
					height: 100%;
				}
			}

			.more{
				transform: translateY(0);
				opacity: 1;
			}
		}
		
		.info{
			padding: 40px 10px 40px 20px;
			color: #fff;
			position: relative;
			z-index: 2;
			transition: all .4s;
			overflow: hidden;

			&:after{
				content: '';
				position: absolute;
				top: 0;
				left: -2%;
				width: 104%;
				height: 0;
				background: #fff;
				z-index: -1;
				transition: all .4s;
			}

			h6{
				font-size: 11px;
				font-weight: 400;
				letter-spacing: 4px;
				text-transform: uppercase;
				margin-bottom: 10px;
			}

			h5{
				font-size: 21px;
			}
		}

		.more{
			position: absolute;
			bottom: 50px;
			right: 50px;
			color: #fff;
			font-size: 13px;
			letter-spacing: 1px;
			text-transform: uppercase;
			transform: translateY(20px);
			opacity: 0;
			transition: all .4s;

			i{
				font-size: 10px;
				margin-left: 2px;
			}
		}
	}

	.glry-img,
	.bg-img {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	.glry-img{
		overflow: hidden;

		.bg-img{
			transform: scale(1.1,1.1);
			opacity: 0;
			transition: all .7s;
			background-size: cover;
			background-position: center center;

			&.current{
				transform: scale(1);
				opacity: 1;
			}
		}
	}
}

.work-carousel{

	.swiper-slide{
		padding: 0px 15px;
	}

	.swiper-slide-active{

		.noraidus{

			.cont{
				opacity: 1;
				visibility: visible;
				transition-delay: .5s;
			}
		}
	}

	.next-ctrl,
	.prev-ctrl{
		color: #000;
		width: 60px;
		height: 60px;
		border-radius: 0;
		border: 0;
		background: #fff;
		opacity: 0;
		transition: all .3s;

		&:after{
			display: none;
		}
	}

	.simp-next,
	.simp-prev{
		color: #fff;
		top: auto;
		bottom: 0;

		&:after{
			display: none;
		}
	}

	.simp-next{
		right: 30px;
	}

	.simp-prev{
		left: 30px;
	}

	&:hover{

		.next-ctrl,
		.prev-ctrl{
			opacity: 1;
		}
	}

	.content{
		position: relative;

		.item-img {
			position: relative;
			height: 480px;
			line-height: 0;
		}

		&:hover{
			
			.item-ig{
				clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
			}
		}

		.cont{
			position: relative;

			h6{
				font-size: 12px;
				font-weight: 500;
				text-transform: uppercase;
				letter-spacing: 2px;
				margin-bottom: 10px;
			}

			h4{
				font-size: 17px;
			}

			.icon{
				width: 35px;
				height: 35px;
				line-height: 35px;
				text-align: center;
				background: rgba(255,255,255,.03);
				border-radius: 50%;
				font-size: 12px;
				position: absolute;
				top: calc(50% - 17px);
				right: 15px;
			}
		}

		.bgbox{
			position: absolute;
			bottom: 35px;
			left: 35px;
		}
	}

	&.caroul{

		.content{
	
			.item-img {
				height: 480px;
				position: relative;

				&:after{
					content: '';
					position: absolute;
					top: 20px;
					left: 20px;
					bottom: 20px;
					right: 20px;
					background: #fff;
					opacity: 0;
					transition: all .4s;
				}
			}

			.bgbox{
				opacity: 0;
				transition: all .4s;
			}

			&:hover{

				.item-img{

					&:after{
						opacity: 1;
					}
				}

				.bgbox{
					opacity: 1;
				}
			}
		}
	}

	.noraidus{

		.item-img {
			height: 480px;
			border-radius: 0;
		}

		.cont{
			position: absolute;
			padding: 15px 30px;
			background: #fff;
			bottom: 0;
			left: 50%;
			transform: translateX(-50%);
			text-align: center;
			opacity: 0;
			visibility: hidden;
			transition: all .4s;

			h6{
				font-size: 12px;
				font-weight: 500;
				text-transform: uppercase;
				letter-spacing: 2px;
				margin-bottom: 10px;
			}

			h4{
				font-size: 17px;
			}
		}
	}
}

.work-carousel2{

	.swiper-slide{
		padding: 0px 10px 50px;

		.img{
			border-radius: 3px;
			overflow: hidden;
			transition: all .8s;
		}
	}

	.swiper-slide-active{
		
		.img{
			box-shadow: 0px 40px 30px -40px rgba(0,0,0,.3);

			&:after{
				opacity: 1 !important;
			}
		}

		.cont{
			
			h6, h4{
				opacity: 1 !important;
				visibility: visible !important;
				transform: translateY(0px) !important;
			}
		}
	}

	.swiper-pagination-progressbar{
		position: absolute;
		bottom: 0;
		top: auto;
		right: auto;
		left: calc(50% - 120px);
		width: 240px;
		height: 2px;
		border-radius: 10px;
		background: #eee;

		.swiper-pagination-progressbar-fill{
			border-radius: 10px;
			background: #1e1e1e;
		}
	}

	.swiper-button-next,
	.swiper-button-prev{
		color: #000;
		width: 60px;
		height: 60px;
		border-radius: 0;
		border: 0;
		background: #fff;
		opacity: 0;
		transition: all .3s;

		&:after{
			display: none;
		}
	}

	&:hover{

		.swiper-button-next,
		.swiper-button-prev{
			opacity: 1;
		}
	}

	.content{
		position: relative;

		.img{
			position: relative;
			line-height: 0;

			&:after{
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background: linear-gradient(to top, rgba(0,0,0,.7), transparent);
				opacity: 0;
				transition: all .5s;
			}
		}

		.cont{
			position: absolute;
			bottom: 40px;
			left: 40px;
			color: #fff;

			h6{
				font-size: 13px;
				font-weight: 500;
				text-transform: uppercase;
				letter-spacing: 4px;
				margin-bottom: 10px;
				opacity: 0;
				visibility: hidden;
				transform: translateY(-20px);
				transition: all .7s;
				transition-delay: .5s;
			}

			h4{
				font-weight: 600;
				opacity: 0;
				visibility: hidden;
				transform: translateY(20px);
				transition: all .7s;
				transition-delay: .5s;
			}
		}
	}

	&.mcolumn{
		
		.swiper-slide{
			padding: 0px;

			.img{
				border-radius: 0;
			}
		}

		.swiper-slide-active{
		
			.img{
				box-shadow: none;
	
				&:after{
					opacity: 1 !important;
				}
			}
		}
	}
}

.half-bg{
	position: absolute;
	left: 0;
	width: 100%;
	height: 200px;
	background: $sub-bg;
	z-index: 1;

	&.bottom{
		bottom: 0;
	}

	&.top{
		top: 0;
	}

	&.not-sub{
		background: #0c0f16;
	}
}

.case-study{
	position: relative;

	.swiper-slide{

		.container{
			min-height: 75vh;
			padding: 100px 30px;
		}

		&.bg-img{
			background-position: center !important;
		}

		.cont{

			span{
				padding: 12px 30px;
				border: 1px solid rgba(255, 255, 255, 0.3);
				border-radius: 30px;
				font-size: 13px;
				margin-bottom: 50px;
			}

			h6{
				font-size: 17px;
				text-transform: uppercase;
				letter-spacing: 1px;
				margin-bottom: 15px;
			}

			h4{
				font-size: 60px;
				text-transform: uppercase;
				letter-spacing: 1px;
			}
		}
	}

	.controls{
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		right: 150px;
		z-index: 4;

		.swiper-button-next,
		.swiper-button-prev{
			position: static;
			width: 60px;
			height: 60px;
			line-height: 60px;
			text-align: center;
			border: 1px solid #eee;
			color: #fff;
			display: block;
			margin: 5px auto;
			cursor: pointer;

			&:after{
				display: none;
			}
		}
	}
}

.works{

	.lg-space{

		> div{
			padding: 0 50px;
			margin-bottom: 50px;
		}
	}
}

.next-prog{

	.box{
		position: relative;

		.bg-img{
			height: 320px;
			width: 320px;
			border-radius: 50%;
			margin: auto;
			background-position: center center;
			position: relative;

			&:before{
				border-radius: 50%;
			}
		}

		.caption, .copy-cap{
            position: absolute;
            top: 0;
            left: 50%;
			height: 100%;
			transform: translateX(-50%);

            h1{
				font-size: 55px;
				font-weight: 800;
                display: block;
                min-width: 700px;
                text-align: center;
                margin: auto;
    
                .stroke{
                    display: inline-block;
                    color: transparent;
                    -webkit-text-stroke: 1px #111;
                }
            }
        }

        .copy-cap{
			z-index: -1;
        }
	}
}

/* ====================== [ End Work ] ====================== */
