

/* ====================== [ Start Heading ] ====================== */

.sec-head{
	position: relative;
	margin: 0 auto 80px;

	h6{
		font-weight: 300;
		font-size: 17px;
		text-transform: uppercase;
		letter-spacing: 7px;
		margin-bottom: 10px;
	}

	h3{
		font-size: 48px;
		font-weight: 600;
	}

	h2{
		font-size: 70px;
		text-transform: uppercase;
		letter-spacing: 2px;
	}
}

.sub-title{
	position: relative;
	margin-bottom: 20px;

	h6{
		font-weight: 300;
		font-size: 15px;
		text-transform: uppercase;
		letter-spacing: 4px;
		display: inline-block;
		background: linear-gradient(to right, rgb(18, 194, 233), rgb(196, 113, 237), rgb(246, 79, 89));
		padding: 7px 12px;
		border-radius: 10px;
	}
}

.main-title{
	font-weight: 600;
	line-height: 1.5;
	margin-bottom: 15px;
}

.extra-title{
	font-weight: 700;
}

.sm-title{
	font-size: 14px;
	font-weight: 600;
	letter-spacing: 1px;
	text-transform: uppercase;
}

.smore{
	position: relative;
	font-size: 13px;
	font-weight: 300;
	letter-spacing: 7px;
	text-transform: uppercase;
	display: inline-block;
	padding: 5px;

	&:after{
		content: '';
		width: 45%;
		height: 100%;
		background: rgba(255,255,255,.03);
		position: absolute;
		left: 0;
		top: 0;
	}

	i{
		margin-left: 10px;
	}
}


.line-v {
    position: absolute;
    height: 80px;
    width: 1px;
    background: #c5a47e;
    left: 50%;
    opacity: .3;
}

.line-v.top {
    top: -30px;
}

.line-v.bottom {
    bottom: -30px;
}


/* ====================== [ End Heading ] ====================== */