
/* ====================== [ Start tabs & list ] ====================== */

.smp-list{
	margin: 0;

	li{
		margin-top: 20px;
		font-size: 15px;
		font-weight: 300;
		padding-left: 20px;
		position: relative;

		&:after{
			content: '';
			position: absolute;
			left: 0;
			top: 50%;
			width: 5px;
			height: 1px;
			background: $main-color;
		}
	}
}

/* ====================== [ Start tabs & list ] ====================== */