

/* ====================== [ Start navbar ] ====================== */

.navbar{
	position: absolute;
	left: 0;
    top: 0;
    padding: 0;
    margin: 0;
	width: 100%;
    background: transparent;
    border-bottom: 2px solid transparent;
	z-index: 999;
	min-height: 80px;

	.icon-bar{
		color: #111;
	}

	&.light{

		.navbar-nav{

			.nav-link{
				color: #111;
			}
		}
	}

	.navbar-nav{
  
		.nav-link{
            font-size: 12px;
            font-weight: 500;
			color: #fff;
            padding: 15px 7px;
            margin: 10px 15px;
		}

		.dropdown-menu{
			padding: 10px 0;
			border: 0;
			box-shadow: $dark-shadow;
		}

		.dropdown-item{
			font-size: 13px;
			padding: 10px 20px;
			opacity: .9;

			&:hover{
				background: transparent;
				color: $main-color;
			}
        }
    }

    .dropdown-menu{
        display: block;
        opacity: 0;
        visibility: hidden;
        border-radius: 0;
        color: #111;
        background: #f5f5f5;
        min-width: 185px;
        transform: translateY(20px);
        transition: all .4s;
    
        &.show{
            opacity: 1;
            visibility: visible;
            transform: translateY(0);
        }
    
        .dropdown-item{
            transition: all .4s;
            padding: 10px 25px;
            position: relative;
    
            &:after{
                content: '';
                width: 0px;
                height: 1px;
                background: $main-color;
                position: absolute;
                left: 10px;
                top: 50%;
                transition: all .4s;
            }
    
            &:hover{
                padding-left: 30px;
                
                &:after{
                    width: 10px;
                }
            }
        }
    }
    
    .search{
        color: #fff;
        padding-left: 30px;
        border-left: 1px solid rgba(255,255,255,.1);
        
        .icon{
            cursor: pointer;
        }

        .search-form{
            position: fixed;
            width: 100%;
            height: 100vh;
            top: 0;
            left: 0;
            background: rgba(37,37,49,.98);
            display: none;
            
            form{
                width: 600px;
                position: absolute;
                left: calc(50% - 300px);
                top: 50%;
                transform: translateY(-50%);

                input{
                    padding: 15px 0;
                    font-size: 80px;
                    color: #fff;
                    text-transform: uppercase;
                    text-align: left;
                    background: transparent;
                    border: 0;
                    border-bottom: 2px solid $main-color;
                }
            }

            .close{
                position: absolute;
                top: 20%;
                right: 20%;
                color: #fff;
                font-size: 60px;
                opacity: 1;
                cursor: pointer;
            }
        }
    }

    .logo{
        width: 50px;
    }
}

.nav-scroll{
    background: #fff;
    border-color: #f5f5f5;
	padding: 0;
	position: fixed;
	top: -80px;
	transition: transform .5s;
	transform: translateY(80px);

	.navbar-nav .nav-link{
		color: #111;
	}
}


.topnav{
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 999999;
	padding: 30px 0;
	color: #fff;
	transition: all .7s;

	&.light{
		color: #111;

		.text{

			&:after{
				color: #111
			}
		}

		.icon{
			
			i{
				background: #111 !important;
			}
		}
	}

	.container,
	.container-fluid{
		display: flex;
	}

	.container-fluid{
		padding: 0 50px;
	}

	.logo{
		width: 50px;
	}

	.menu-icon{
		margin-left: auto;
		cursor: pointer;

		&:hover{

			.text{
				
				.char {
					animation: fadeInUp .3s cubic-bezier(.3, 0, .7, 1) both;
					animation-delay: calc(30ms * var(--char-index));
				}
			}
		}

		.text{
			font-size: 13px;
			font-weight: 500;
			padding-left: 15px;
			display: table-cell;
			vertical-align: middle;
			position: relative;

			&:after{
				content: 'close';
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				padding-left: 15px;
				opacity: 0;
				transition: all .4s;
				transition-delay: .4s;
			}

			.word{
				transition: all .4s;
				transition-delay: .6s;
			}

			&.open{

				.word{
					opacity: 0;
					transition-delay: .4s;
				}

				&:after{
					opacity: 1;
					transition-delay: .6s;
				}
			}
		}

		.icon{
			display: table-cell;
			
			i{
				display: block;
				width: 20px;
				height: 3px;
				background: #fff;
				margin: 4px 0;
			}
		}
	}
}

.hamenu{
	position: fixed;
	top: 0;
	left: -100%;
	width: 100%;
	height: 100vh;
	background: #eee;
	padding: 120px 30px 30px;
	overflow: hidden;
	z-index: 9999;
	transition: all 0.5s cubic-bezier(1, 0, 0.55, 1);

	&.open{

		.menu-links{

			.main-menu{

				> li{
					
					.link{
						transform: translateY(0);
						transition-delay: 1s;
					}
				}
			}
		}

		.cont-info{

			&:after{
				height: 100vh;
				transition-delay: 1s;
			}

			.item{
				transform: translateY(0);
				opacity: 1;
				transition-delay: 1s;
			}
		}
	}

	.menu-links{
		margin-top: 30px;

		.o-hidden{
			display: inline-block;
		}

		.main-menu{
			position: relative;
			z-index: 2;

			&.gosub{

				> li{
					opacity: 0;
					visibility: hidden;
					transition-delay: .2s !important;

					.link{
						transform: translateY(45px) !important;
						opacity: 0 !important;
						visibility: hidden !important;
						transition-delay: .2s !important;
					}
				}

				.sub-menu.sub-open{
					z-index: 3 !important;
					opacity: 1 !important;
					visibility: visible !important;
				}
			}

			> li{
				font-size: 30px;
				font-weight: 600;
				padding: 10px;
				width: max-content;
				opacity: 1;
				visibility: visible;
				transition: all .5s;

				span.nm{
					opacity: .8;
					font-size: 11px;
					margin-right: 10px;
				}

				.link{
					transform: translateY(45px);
					transition: all .7s;
					cursor: pointer;

					i{
						font-size: 15px;
						margin-left: 5px;
					}
				}
			}

			.sub-menu{
				position: absolute;
				top: 0;
				left: 0;
				opacity: 0;
				visibility: hidden;
				z-index: -1;
				transition-delay: .2s;

				&.sub-open{
					z-index: 3;

					li{

						.sub-link{
							transform: translateY(0px);
							transition-delay: 1s;
						}
					}
				}

				li{
					color: #333;
					font-size: 20px;
					font-weight: 400;
					letter-spacing: 1px;
					padding: 8px 10px;
					width: max-content;
	
					.sub-link{
						transform: translateY(45px);
						transition: all .7s;

						&.back{
							color: #000;
							font-size: 20px;
							font-weight: 600;
							cursor: pointer;

							i{
								margin-left: 10px;
							}
						}

						em{
							font-size: 13px;
							font-weight: 300;
							margin-left: 10px;
						}
					}
				}
			}
		}
	}

	.cont-info{
		margin-top: 50px;
		position: relative;

		&:after{
			content: '';
			width: 1px;
			height: 0;
			background: rgba(0,0,0,.1);
			position: absolute;
			left: -40px;
			top: -170px;
			transition: all .7s;
		}

		.item{
			margin-bottom: 30px;
			transform: translateY(-30px);
			opacity: 0;
            transition: all .7s;
            
            &:last-of-type{
                margin-bottom: 0;
			}

			h6{
				font-size: 14px;
				font-weight: 600;
				text-transform: uppercase;
				margin-bottom: 10px;
			}
		}
	}
}

/* ====================== [ End navbar ] ====================== */