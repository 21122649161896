

/* ====================== [ Start Team ] ====================== */

.team{
	position: relative;

	.item{
		padding: 0 25px;

		&.cir{

			.img{
				position: relative;
	
				&:hover{
	
					&:after{
						opacity: 1;
						transform: rotate(4deg);
					}
	
					#circle,
					.info{
						opacity: 1;
					}
	
					&:before{
						opacity: .5;
					}
				}
	
				&:after{
					content: '';
					position: absolute;
					top: 5px;
					left: 0;
					right: 0;
					bottom: 5px;
					background: linear-gradient(to right, #12c2e9, #c471ed, #f64f59);
					opacity: 0;
					z-index: -1;
					transition: all .7s;
				}
	
				&:before{
					content: '';
					position: absolute;
					top: 0px;
					left: 0;
					right: 0;
					bottom: 0px;
					background: #000;
					opacity: 0;
					transition: all .4s;
				}
			}

			.info{
				position: absolute;
				bottom: 15px;
				left: 15px;
				opacity: 0;
				transition: all .4s;
				z-index: 2;
	
				span{
					font-size: 13px;
					color: $main-color;
				}
			}
		}

		.img{
			position: relative;

			&:hover{

				&:after{
					opacity: 1;
					transform: rotate(4deg);
				}

				#circle,
				.info{
					opacity: 1;
					color: #fff;
				}

				&:before{
					opacity: .3;
				}
			}

			&:after{
				content: '';
				position: absolute;
				top: 5px;
				left: 0;
				right: 0;
				bottom: 5px;
				background: linear-gradient(to right, #12c2e9, #c471ed, #f64f59);
				opacity: 0;
				z-index: -1;
				transition: all .7s;
			}
		}
	}

	&.bord{

		.item{

			&:hover{

				.img{

					&:after{
						border-color: $main-color;
						opacity: 1;
						transform: rotate(0deg) !important;
					}
				}
			}

			.img{
				padding: 30px;

				&:after{
					content: '';
					position: absolute;
					top: 0;
					left: 0;
					right: 0;
					bottom: 5px;
					background: transparent;
					border: 1px solid #fff;
					opacity: .1;
					transform: rotate(-5deg);
					z-index: -1;
					transition: all .7s;
				}

				&.left{

					&:after{
						transform: rotate(5deg);
					}
				}
			}

			.info{
				margin-top: 15px;
				text-align: center;
	
				span{
					font-size: 13px;
					color: $main-color;
				}
			}
		}
	}

	.slick-dots{
		bottom: -40px;
	}

	.slick-dots li.slick-active button{
		background: $main-color;
	}

	.slick-dots li button{
		width: 10px;
		height: 10px;
		border-radius: 50%;
		opacity: .8;

		&:before{
			content: '';
			width: 4px;
			height: 4px;
			border-radius: 50%;
			position: absolute;
			top: 4px;
			left: 4px;
			line-height: 1;
			background: $main-color;
			opacity: 1;
		}
	}

	.dots-half{
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 70%;
		z-index: -2;
	}
}


#circle { position: absolute; top: -30%; right: -50%; width: 100%; padding-bottom: 100%; overflow: hidden; pointer-events: none; opacity: 0; transition: opacity .4s;}

#circle text { font-size: 13px; letter-spacing: 4px; }

#circle svg { position: absolute; left: 0; top: 0; width: 100%; height: 300px;

  -webkit-animation-name: rotate;
     -moz-animation-name: rotate;
      -ms-animation-name: rotate;
       -o-animation-name: rotate;
          animation-name: rotate;
  -webkit-animation-duration: 8s;
     -moz-animation-duration: 8s;
      -ms-animation-duration: 8s;
       -o-animation-duration: 8s;
          animation-duration: 8s;
  -webkit-animation-iteration-count: infinite;
     -moz-animation-iteration-count: infinite;
      -ms-animation-iteration-count: infinite;
       -o-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
     -moz-animation-timing-function: linear;
      -ms-animation-timing-function: linear;
       -o-animation-timing-function: linear;
          animation-timing-function: linear;

}

@-webkit-keyframes rotate {
    from { -webkit-transform: rotate(360deg); }
    to { -webkit-transform: rotate(0); }
}
@-moz-keyframes rotate {
    from { -moz-transform: rotate(360deg); }
    to { -moz-transform: rotate(0); }
}
@-ms-keyframes rotate {
    from { -ms-transform: rotate(360deg); }
    to { -ms-transform: rotate(0); }
}
@-o-keyframes rotate {
    from { -o-transform: rotate(360deg); }
    to { -o-transform: rotate(0); }
}
@keyframes rotate {
    from { transform: rotate(360deg); }
    to { transform: rotate(0); }
}

/* ====================== [ End Team ] ====================== */