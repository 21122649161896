

$body-color: #111;
$pcolor: #888;
$sub-bg: #f5f5f5;
$main-color: rgb(18, 194, 233);
$dark-shadow: 0px 10px 40px -4px rgba(0,0,0,.1); 
$mobile-xs-screen: 480px;
$tablet-sm-screen: 767px;
$desk-md-screen: 991px;
$desk-lg-screen: 1200px;
