.hero-bg2 {
    background-image: url("../../src/img/bf_flores.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.historia{
    padding-top: 5%;
    padding-bottom: 5%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.social-icon2{
    font-size: 18px;
    color: #4267B2;
}

.container2 {
    position: relative;
  }
  
  .image2{
    display: block;
    width: 100%;
    height: auto;
  }
  
  .overlay2 {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: .5s ease;
    background-color: rgba($color: #000000, $alpha: 0.5);
  }
  
  .container2:hover .overlay2 {
    opacity: 1;
  }
  
  .text2 {
    color: white;
    font-size: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
  }

  .flotante{
    width: 8rem;
    height: 8rem;
  }

  .portada{
    width: 40%;
  }