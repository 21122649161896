

/* ====================== [ Start contact ] ====================== */

.contact-sec{

    .form{

        .form-group{
            margin-bottom: 30px;

            input,textarea{
                width: 100%;
                padding: 15px 0;
                border: 0;
                border-bottom: 1px solid rgba(0,0,0,.1);
                background: transparent;
            }
        }
    }

    &.style2{

        .form{

            input,textarea{
                padding: 20px;
                background: rgba(0,0,0,.05);
                backdrop-filter: blur(2px);
            }
        }
    }
}

.contact{

    .form{

        input,textarea{
            color: #fff;
            width: 100%;
            padding: 15px 0;
            background: transparent;
            border: 0;
            border-bottom: 1px solid rgba(0,0,0,.3);
        }

        textarea{
            padding: 15px;
            min-height: 140px;
            border: 1px solid rgba(0,0,0,.3);
            margin-top: 30px;
        }
    }

    .cont-info{

        h3{
            font-size: 18px;
            font-weight: 400;
            letter-spacing: 2px;
            margin-bottom: 10px;
        }

        .item{

            h5{
                font-size: 16px;
                font-weight: 400;
                color: #333;
                margin: 10px 0;
            }

            h6{
                font-size: 14px;
                font-weight: 300;
                color: #555;
                line-height: 1.7;
            }
        }

        .social{

            a{
                width: 45px;
                height: 45px;
                line-height: 45px;
                text-align: center;
                background: rgba(0,0,0,.01);
                border-radius: 50%;
                margin-right: 5px;
                font-size: 13px;
            }
        }
    }
}

.map{
    width: 100%;
    height: 60vh;
}

/* ====================== [ End contact ] ====================== */